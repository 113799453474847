<script>
    import { onMount, onDestroy, getContext } from 'svelte';

    import LOANS_CONFIG from 'configs/loans';

    import authStore from 'stores/auth';
    import loansStore from 'stores/loans';

    import AppLayout from 'components/AppLayout';
    import Loader from 'components/Loader';
    import LoanCard from 'components/LoanCard';
    import LoanCardSkeleton from 'components/LoanCardSkeleton';
    import LoanDetails from 'components/LoanDetails';
    import LoanDetailsSkeleton from 'components/LoanDetailsSkeleton';
    import NewLoanOverlay from 'components/NewLoanOverlay';
    import LoanAgreementOverlay from 'components/LoanAgreementOverlay';
    import { AlertModal, alertModalOptions } from 'components/AlertModal';

    const { open } = getContext('simple-modal');

    let isLoading = true;
    let selectedFilter = LOANS_CONFIG.LOAN_FILTERS.ACTIVE;
    let selectedLoans = [];
    let selectedLoan = null;
    let newLoanOverlay = false;
    let loanAgreementOverlay = false;

    onMount(() => {
        loadCustomerLoans();
    });

    onDestroy(() => {});

    function loadCustomerLoans(force = false) {
        isLoading = true;

        loansStore.fetchLoans($authStore.customerId, force).finally(() => {
            setTimeout(() => {
                isLoading = false;
            }, 500);
            setFilter(LOANS_CONFIG.LOAN_FILTERS.ACTIVE);
        });
    }

    function setFilter(filter) {
        selectedFilter = filter;

        switch (selectedFilter.key) {
            case LOANS_CONFIG.LOAN_FILTERS.ACTIVE.key:
                selectedLoans = $loansStore.loans.active;
                break;
            case LOANS_CONFIG.LOAN_FILTERS.PAID.key:
                selectedLoans = $loansStore.loans.paid;
                break;
            case LOANS_CONFIG.LOAN_FILTERS.CANCELLED.key:
                selectedLoans = $loansStore.loans.cancelled;
                break;
            default:
                selectedLoans = [];
                break;
        }

        selectLoan(selectedLoans[0]);
    }

    function selectLoan(loan) {
        selectedLoan = loan;
    }

    function handleClickNewLoan() {
        let errorMsg;
        if ($loansStore.loans.active.length > 0) {
            let pendingLoans = $loansStore.loans.active.filter((loan) => {
                return loan.status == LOANS_CONFIG.LOAN_STATUSES.PENDING;
            });

            if (pendingLoans.length > 0) {
                errorMsg = 'You cannot apply a for new loan or top-up with existing Pending loans.';
            }
        }

        if (errorMsg) {
            open(
                AlertModal,
                {
                    title: "Sorry, you can't do that right now",
                    message: errorMsg,
                },
                alertModalOptions,
            );
        } else {
            newLoanOverlay = true;
        }
    }
</script>

<NewLoanOverlay
    trigger={newLoanOverlay}
    onClose={() => (newLoanOverlay = false)}
    onSubmit={() => {
        loadCustomerLoans(true);
    }}
/>

{#if selectedLoan}
    <LoanAgreementOverlay
        trigger={loanAgreementOverlay}
        loan={selectedLoan ? selectedLoan : null}
        onSubmit={() => {
            loadCustomerLoans(true);
        }}
        onClose={() => {
            loanAgreementOverlay = false;
        }}
    />
{/if}

<div class="min-h-screen overflow-x-hidden bg-neutral">
    <AppLayout title="Loans" subtitle="View, modify and action on your existing and past loans">
        <svelte:fragment slot="actions">
            <button
                class="btn btn-primary btn-active w-full text-white lg:w-auto"
                on:click={() => {
                    window.open('https://www.fygaro.com/en/pb/9b60fb2e-512c-49e8-b125-52a3a2fbdb63/', '_blank');
                }}>Make a Payment</button
            >
            <button
                class="btn btn-primary btn-active w-full text-white lg:w-auto"
                on:click={() => {
                    handleClickNewLoan();
                }}>Apply for a Loan / Top-up</button
            >
        </svelte:fragment>

        <svelte:fragment slot="page">
            <div class="flex flex-1 flex-col overflow-hidden bg-gradient-to-b from-neutral to-white">
                <!-- {#if !isLoading} -->
                <div>
                    <div class="flex w-full items-center justify-start border-b border-neutral bg-white">
                        {#each Object.keys(LOANS_CONFIG.LOAN_FILTERS) as filterKey, i}
                            <div
                                class="flex w-full cursor-pointer flex-col items-center justify-between gap-4 border-b-2 border-r border-b-transparent border-r-secondary px-4 py-6 hover:border-b-primary lg:flex-row lg:gap-0"
                                on:keydown
                                on:click={() => setFilter(LOANS_CONFIG.LOAN_FILTERS[filterKey])}
                                class:!border-b-primary={selectedFilter.key ==
                                    LOANS_CONFIG.LOAN_FILTERS[filterKey].key && !isLoading}
                            >
                                <div
                                    class="flex w-full items-center justify-center space-x-2 font-normal text-gray-500 lg:text-lg"
                                    class:!text-primary={selectedFilter.key == LOANS_CONFIG.LOAN_FILTERS[filterKey].key}
                                    class:!font-medium={selectedFilter.key == LOANS_CONFIG.LOAN_FILTERS[filterKey].key}
                                >
                                    {#if isLoading}
                                        <div class="flex w-full items-center justify-start gap-x-2">
                                            <div class="skeleton size-6 rounded-full bg-gray-200"></div>
                                            <div class="skeleton h-4 w-3/4 bg-gray-200"></div>
                                        </div>
                                    {:else}
                                        <span
                                            ><i
                                                class="fa-regular fa-lg lg:fa-xl {LOANS_CONFIG.LOAN_FILTERS[filterKey]
                                                    .icon} {LOANS_CONFIG.LOAN_FILTERS[filterKey].color}"
                                            ></i></span
                                        >
                                        <span>{LOANS_CONFIG.LOAN_FILTERS[filterKey].label}</span>
                                    {/if}
                                </div>
                                {#if isLoading}
                                    <div class="skeleton size-6 bg-gray-200"></div>
                                {:else}
                                    <div class="badge badge-secondary p-3 text-primary">
                                        {$loansStore.loans[filterKey.toLowerCase()]?.length ?? 0}
                                    </div>
                                {/if}
                            </div>
                        {/each}
                    </div>
                </div>
                <!-- {/if} -->
                <div
                    class="relative flex w-full flex-grow flex-col items-start justify-between overflow-hidden px-0 py-0 lg:flex-row lg:overflow-auto lg:pb-24"
                >
                    {#if selectedLoans.length > 0 || isLoading}
                        <div
                            class="flex max-h-[300px] w-full snap-x snap-mandatory items-center overflow-x-auto p-4 lg:block lg:max-h-screen lg:w-[40%] lg:snap-none lg:pb-6"
                            class:overflow-x-hidden={selectedLoans.length <= 1}
                        >
                            {#if isLoading}
                                {#each { length: 20 } as _, i}
                                    <div class="mr-2 w-full flex-shrink-0 snap-center lg:mb-2 lg:mr-0 lg:snap-none">
                                        <LoanCardSkeleton />
                                    </div>
                                {/each}
                            {:else}
                                {#each selectedLoans as loan, i (loan.id)}
                                    <div
                                        class="mr-2 w-full flex-shrink-0 snap-center lg:mb-2 lg:mr-0 lg:snap-none"
                                        class:mx-0={selectedLoans.length <= 1}
                                    >
                                        <LoanCard
                                            {loan}
                                            isSelected={loan.id == selectedLoan.id}
                                            onClick={() => selectLoan(loan)}
                                        />
                                    </div>
                                {/each}
                            {/if}
                        </div>
                        {#if isLoading}
                            <LoanDetailsSkeleton />
                        {:else}
                            <LoanDetails
                                loan={selectedLoan}
                                on:signature={(e) => {
                                    loanAgreementOverlay = true;
                                }}
                            />
                        {/if}
                    {:else}
                        <div class="flex h-full w-full items-center justify-center pt-24">
                            <Loader
                                animationPath="lottie/alert-list.json"
                                animationLoop={false}
                                title={`We can't find any ${selectedFilter.label} loans.`}
                                subtitle="Try selecting another filter from the list above."
                            />
                        </div>
                    {/if}
                </div>
            </div>
        </svelte:fragment>
    </AppLayout>
</div>

<style lang="scss" type="text/scss">
    @use 'src/sass/base' as *;
    @use 'src/sass/tailwind';
</style>
