<script>
</script>

<div
    class="flex h-full w-full cursor-pointer flex-col items-center justify-between rounded-md border border-neutral bg-white px-4 py-4 transition-all hover:border-primary hover:shadow-sm"
>
    <!-- {#if loan.status == LOANS_CONFIG.LOAN_STATUSES.ACTIVE || loan.status == LOANS_CONFIG.LOAN_STATUSES.PENDING} -->
    <div class="relative mb-4 h-[7px] w-full overflow-hidden rounded-md bg-neutral">
        <div class="skeleton h-12 w-full bg-gray-200"></div>
    </div>
    <!-- {/if} -->
    <div class="mb-6 flex w-full items-center justify-between">
        <div class="skeleton h-6 w-1/2 bg-gray-200"></div>
        <div class="flex items-center justify-center gap-x-2">
            <div class="skeleton h-6 w-12 bg-gray-200"></div>
            <div class="skeleton h-6 w-12 bg-gray-200"></div>
        </div>
    </div>
    <div class="space-between flex w-full items-center">
        <div class="flex w-full flex-col gap-2 whitespace-nowrap text-sm">
            <div class="flex items-center justify-start gap-2 text-gray-500">
                <div class="skeleton size-6 rounded-full bg-gray-200"></div>
                <div class="skeleton h-4 w-1/2 bg-gray-200"></div>
            </div>
            <div class="skeleton h-4 w-3/4 bg-gray-200"></div>
        </div>
        <div class="flex w-full flex-col gap-2 whitespace-nowrap text-sm">
            <div class="flex items-center justify-start gap-2 text-gray-500">
                <div class="skeleton size-6 rounded-full bg-gray-200"></div>
                <div class="skeleton h-4 w-1/2 bg-gray-200"></div>
            </div>
            <div class="skeleton h-4 w-3/4 bg-gray-200"></div>
        </div>
        <div class="flex w-full flex-col gap-2 whitespace-nowrap text-sm">
            <div class="flex items-center justify-start gap-2 text-gray-500">
                <div class="skeleton size-6 rounded-full bg-gray-200"></div>
                <div class="skeleton h-4 w-1/2 bg-gray-200"></div>
            </div>
            <div class="skeleton h-4 w-3/4 bg-gray-200"></div>
        </div>
    </div>
</div>

<style lang="scss">
    @use 'src/sass/base' as *;
    @use 'src/sass/tailwind';
</style>
