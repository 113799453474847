<script>
</script>

<div
    class="flex w-full flex-1 flex-col self-stretch border-l border-neutral bg-white px-6 py-4 lg:ml-2 lg:w-[60%] lg:p-6"
>
    <div>
        <section class="mb-6 w-full">
            <div class="mb-6 flex items-center justify-start gap-x-2 border-b border-neutral p-2">
                <div class="skeleton size-8 bg-gray-200"></div>
                <div class="skeleton h-8 w-1/2 bg-gray-200"></div>
            </div>
            <div class="space-between flex w-full items-center">
                <div class="mr-6 w-[60%]">
                    <div class="space-between flex flex-wrap items-start">
                        <div class="mb-10 flex w-full flex-col gap-2">
                            <div class="skeleton h-4 w-1/2 bg-gray-200"></div>
                            <div class="skeleton h-4 w-1/2 bg-gray-200"></div>
                        </div>
                        <div class="mb-10 flex w-[50%] flex-col gap-2">
                            <div class="skeleton h-4 w-1/2 bg-gray-200"></div>
                            <div class="skeleton h-4 w-1/2 bg-gray-200"></div>
                        </div>
                        <div class="mb-10 flex w-[50%] flex-col gap-2">
                            <div class="skeleton h-4 w-1/2 bg-gray-200"></div>
                            <div class="skeleton h-4 w-1/2 bg-gray-200"></div>
                        </div>
                        <div class="mb-0 flex w-full flex-col gap-2 lg:w-[45%]">
                            <div class="skeleton h-4 w-1/2 bg-gray-200"></div>
                            <div class="skeleton h-4 w-1/2 bg-gray-200"></div>
                        </div>
                    </div>
                </div>
                <div class="w-[40%]">
                    <div class="skeleton size-36 rounded-full bg-gray-200 lg:size-64"></div>
                </div>
            </div>
        </section>
        <section class="mb-6 w-full">
            <div class="mb-6 flex items-center justify-start gap-x-2 border-b border-neutral p-2">
                <div class="skeleton size-8 bg-gray-200"></div>
                <div class="skeleton h-8 w-1/2 bg-gray-200"></div>
            </div>
            <div class="overflow-x-auto">
                <table class="table table-zebra table-md">
                    <!-- head -->
                    <thead>
                        <tr class="border-b-neutral">
                            <th></th>
                            <th><div class="skeleton h-4 w-full bg-gray-200"></div></th>
                            <th><div class="skeleton h-4 w-full bg-gray-200"></div></th>
                            <th><div class="skeleton h-4 w-full bg-gray-200"></div></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="border-b-neutral">
                            <th><div class="skeleton size-6 bg-gray-200"></div></th>
                            <td><div class="skeleton h-4 w-full bg-gray-200"></div></td>
                            <td><div class="skeleton h-4 w-full bg-gray-200"></div></td>
                            <td><div class="skeleton h-4 w-12 bg-gray-200"></div> </td>
                        </tr>
                        <tr class="border-b-neutral">
                            <th><div class="skeleton size-6 bg-gray-200"></div></th>
                            <td><div class="skeleton h-4 w-full bg-gray-200"></div></td>
                            <td><div class="skeleton h-4 w-full bg-gray-200"></div></td>
                            <td><div class="skeleton h-4 w-12 bg-gray-200"></div> </td>
                        </tr>
                        <tr class="border-b-neutral">
                            <th><div class="skeleton size-6 bg-gray-200"></div></th>
                            <td><div class="skeleton h-4 w-full bg-gray-200"></div></td>
                            <td><div class="skeleton h-4 w-full bg-gray-200"></div></td>
                            <td><div class="skeleton h-4 w-12 bg-gray-200"></div> </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>
        <section class="mb-6 w-full">
            <div class="mb-6 flex items-center justify-start gap-x-2 border-b border-neutral p-2">
                <div class="skeleton size-8 bg-gray-200"></div>
                <div class="skeleton h-8 w-1/2 bg-gray-200"></div>
            </div>
            <div class="space-y-4">
                <div class="skeleton h-4 w-12 bg-gray-200"></div>
                <div class="skeleton h-4 w-12 bg-gray-200"></div>
            </div>
        </section>
    </div>
</div>

<style lang="scss">
    @use 'src/sass/base' as *;
    @use 'src/sass/tailwind';
</style>
