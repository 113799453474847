<script>
    export let iconUrl = null;
    export let primary = true;
    export let primaryInverse = false;
    export let secondary = false;
    export let secondaryInverse = false;
    export let transparent = false;
    export let cancel = false;
    export let fullWidth = false;
    export let disabled = false;
    export let small = false;
    export let medium = false;
    export let type = 'button';
    export let onClick = () => {};
</script>

<button
    class:primary
    class:primaryInverse
    class:secondary
    class:secondaryInverse
    class:transparent
    class:cancel
    class:fullWidth
    class:small
    class:medium
    on:click|preventDefault|stopPropagation={onClick}
    {type}
    {disabled}
>
    {#if iconUrl != null}<img alt="icon" class="icon" height="20" src={iconUrl} />{/if}
    <slot />
</button>

<style lang="scss">
    @use 'src/sass/base' as *;

    .primary {
        @include btn-primary;
    }

    .primaryInverse {
        @include btn-primary-inverse;
    }

    .secondary {
        @include btn-secondary;
    }

    .secondaryInverse {
        @include btn-secondary-inverse;
    }

    .transparent {
        @include btn-primary;
        background: transparent;
        color: $color-white;

        &:hover {
            background: transparent;
            box-shadow: none;
        }
    }

    .cancel {
        @extend .secondary;
        background: transparent;
        color: $color-primary;
        border-color: $color-primary;

        &:hover {
            border-color: darken($color-primary, 10%);
        }
    }

    .fullWidth {
        width: 100%;
    }

    .small {
        font-size: em(13);
    }

    .medium {
        font-size: em(16);
    }

    img {
        @include btn-icon;
    }
</style>
