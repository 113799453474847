import routes from './routes.js';
import App from './components/App';

const app = new App({
    target: document.body,
    props: {
        routes: routes,
    },
});

export default app;
