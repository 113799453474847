import SupportModal from 'SupportModal.svelte';

const supportModalOptions = {
    styleWindow: {
        borderRadius: '25px',
    },
    styleContent: {
        padding: 0,
    },
    closeButton: false,
    closeOnEsc: true,
    closeOnOuterClick: true,
};

export { supportModalOptions, SupportModal };
