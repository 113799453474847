import ForgotPasswordModal from 'ForgotPasswordModal.svelte';

const forgotPasswordModalOptions = {
    styleWindow: {
        width: '400px',
        borderRadius: '25px',
    },
    styleContent: {
        padding: 0,
    },
    closeButton: false,
    closeOnEsc: false,
    closeOnOuterClick: false,
};

export { forgotPasswordModalOptions, ForgotPasswordModal };
