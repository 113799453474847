import { get, writable } from 'svelte/store';

import LOANS_CONFIG from 'configs/loans';

import { getTimeElapsed } from 'services/util';
import { getCustomerLoans, getLoanPayments } from 'services/infinity';

const LOANS_EXPIRED_THRESHOLD_SECONDS = 60;

const initialValue = {
    _loans: [],
    loans: {
        active: [],
        paid: [],
        cancelled: [],
    },
    payments: {},
    dateLastUpdated: null,
};

function createStore() {
    const { subscribe, update, set } = writable(initialValue);

    return {
        subscribe,

        fetchLoans: async function (customerId, force = false) {
            let store = get(this);
            let timeSinceLastFetch = getTimeElapsed(new Date(), store.dateLastUpdated);

            if (force || !store.dateLastUpdated || timeSinceLastFetch >= LOANS_EXPIRED_THRESHOLD_SECONDS) {
                console.debug(`Loans expired after ${timeSinceLastFetch} seconds. Refreshing from API ...`);
                let { loans } = await getCustomerLoans({ customerId });
                this.setLoans(loans);
            }

            return store;
        },

        fetchPayments: async function (customerId, loanId) {
            let store = get(this);
            let _payments = store.payments[loanId];

            if (_payments) {
                return _payments;
            } else {
                let { payments } = await getLoanPayments({ customerId, loanId });
                update((store) => {
                    return {
                        ...store,
                        payments: {
                            ...store.payments,
                            [loanId]: payments,
                        },
                    };
                });

                return payments;
            }
        },

        setLoans: (loans) => {
            let activeLoans = loans.filter((loan) => {
                return (
                    loan.status == LOANS_CONFIG.LOAN_STATUSES.ACTIVE ||
                    loan.status == LOANS_CONFIG.LOAN_STATUSES.PENDING ||
                    loan.status == LOANS_CONFIG.LOAN_STATUSES.PAST_DUE
                );
            });

            let cancelledLoans = loans.filter((loan) => {
                return (
                    loan.status == LOANS_CONFIG.LOAN_STATUSES.DENIED ||
                    loan.status == LOANS_CONFIG.LOAN_STATUSES.REVERSED ||
                    loan.status == LOANS_CONFIG.LOAN_STATUSES.WITHDRAWN
                );
            });

            let paidLoans = loans.filter((loan) => loan.status == LOANS_CONFIG.LOAN_STATUSES.PAID);

            set({
                _loans: loans,
                loans: {
                    active: activeLoans,
                    paid: paidLoans,
                    cancelled: cancelledLoans,
                },
                payments: {},
                dateLastUpdated: new Date(),
            });
        },

        reset: () => {
            set(initialValue);
        },
    };
}

export default createStore();
