<script>
</script>

<div class="container">
    <slot />
</div>

<style lang="scss">
    @use 'src/sass/base' as *;

    .container {
        margin: 0 auto;
        padding: 0 em(25);

        @include for-tablet-portrait-up {
            margin: 0 auto;
            padding: 0 em(25);
        }

        @include for-desktop-up {
            margin: 0 auto;
            padding: 0 em(25);
        }
    }
</style>
