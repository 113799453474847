import LoadingModal from 'LoadingModal.svelte';

const loadingModalOptions = {
    styleWindow: {
        width: '400px',
        borderRadius: '25px',
    },
    styleContent: {
        padding: 0,
    },
    closeButton: false,
    closeOnEsc: false,
    closeOnOuterClick: false,
};

export { loadingModalOptions, LoadingModal };
