export function formatCurrency(amount, locale, currency = 'USD') {
    return new Intl.NumberFormat(`en`, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 2,
    }).format(amount);
}

export function convertDocsToArray(docs) {
    let arr = [];
    docs.forEach((doc) => {
        arr.push({ id: doc.id, ...doc.data() });
    });

    return arr;
}

export function sanitizeObject(obj) {
    return JSON.parse(JSON.stringify(obj, (k, v) => (v === undefined ? null : v)));
}

export function getTimeElapsed(startDate, endDate) {
    if (!startDate || !endDate) {
        return 0;
    }

    let timeDiff = startDate - endDate;
    timeDiff /= 1000;
    return Math.round(timeDiff);
}

export function getBase64StringFromDataURL(dataURL) {
    return dataURL.replace('data:', '').replace(/^.+,/, '');
}

export default {
    formatCurrency,
    convertDocsToArray,
    sanitizeObject,
    getTimeElapsed,
    getBase64StringFromDataURL,
};
