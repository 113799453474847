<script>
    import { getContext } from 'svelte';
    import { link, push, location } from 'svelte-spa-router';
    import active from 'svelte-spa-router/active';

    import authStore from 'stores/auth';
    import customerStore from 'stores/customer';
    import docsStore from 'stores/docs';
    import loansStore from 'stores/loans';
    import registerStore from 'stores/register';

    import Lottie from 'components/Lottie';
    import { SupportModal, supportModalOptions } from 'components/SupportModal';

    const { open } = getContext('simple-modal');

    export let title;

    function openSupportModal() {
        open(SupportModal, {}, supportModalOptions);
    }

    function logout() {
        authStore.reset();
        customerStore.reset();
        docsStore.reset();
        loansStore.reset();
        registerStore.reset();

        push('/');
    }
</script>

<div class="m-0 min-h-screen p-0">
    <div
        class="overflox-hidden mx-0 my-auto flex min-h-screen w-full flex-col items-start justify-start bg-white lg:flex-row"
    >
        <!-- Nav -->
        <div
            class="border-secondary-500 fixed
            z-[999] flex w-full flex-row justify-between border-r
             bg-secondary px-2 py-4 !text-primary shadow-md lg:min-h-screen lg:w-[200px] lg:flex-col lg:border-none lg:px-4 lg:py-6 lg:shadow-sm"
        >
            <div class="flex w-full items-center justify-between lg:block">
                <!-- Logo -->
                <div class="flex h-full items-center justify-center lg:justify-start lg:pb-8">
                    <img src="/images/logo.svg" alt="logo" class="m-0 h-[45px] w-auto p-0 lg:h-[60px]" />
                </div>

                <!-- Links -->
                <div class="flex flex-row lg:flex-col">
                    <div class="flex items-center justify-start px-1 py-2 lg:px-0">
                        <button
                            class="m-0 flex items-center justify-start gap-x-3 p-0"
                            on:keydown
                            on:click={() => push('/loans')}
                        >
                            <div class="w-[26px]">
                                <Lottie
                                    jsonPath="lottie/slide_1_icon.json"
                                    loop={$location == '/loans'}
                                    autoplay={$location == '/loans'}
                                />
                            </div>

                            <div class="hidden text-base lg:block" class:font-bold={$location == '/loans'}>Loans</div>
                        </button>
                    </div>
                    <div class="flex items-center justify-start px-1 py-2 lg:px-0">
                        <button
                            class="m-0 flex items-center justify-start gap-x-3 p-0"
                            on:keydown
                            on:click={() => push('/docs')}
                        >
                            <div class="flex w-[26px] items-center justify-center">
                                <Lottie
                                    jsonPath="lottie/nav_documents_icon.json"
                                    loop={$location == '/docs'}
                                    autoplay={$location == '/docs'}
                                />
                            </div>

                            <div class="hidden text-base lg:block" class:font-bold={$location == '/docs'}>Docs</div>
                        </button>
                    </div>
                    <div class="flex items-center justify-start px-1 py-2 lg:px-0">
                        <button
                            class="m-0 flex items-center justify-start gap-x-3 p-0"
                            on:keydown
                            on:click={() => push('/profile')}
                        >
                            <div class="flex w-[26px] items-center justify-center">
                                <Lottie
                                    jsonPath="lottie/nav_profile_icon.json"
                                    loop={$location == '/profile'}
                                    autoplay={$location == '/profile'}
                                />
                            </div>

                            <div class="hidden text-base lg:block" class:font-bold={$location == '/profile'}>
                                Profile
                            </div>
                        </button>
                    </div>
                </div>
            </div>

            <!-- Org -->
            <div class="flex items-center justify-start lg:block">
                <div class="flex items-center justify-start px-1 py-2 lg:px-0">
                    <button
                        class="m-0 flex items-center justify-start gap-x-3 p-0"
                        on:keydown
                        on:click={() => openSupportModal()}
                    >
                        <div class="flex w-[26px] items-center justify-center">
                            <Lottie jsonPath="lottie/nav_support_icon.json" loop={false} />
                        </div>
                        <div class="hidden text-base lg:block">Support</div>
                    </button>
                </div>
                <div class="flex items-center justify-start px-1 py-2 lg:px-0">
                    <button
                        class="m-0 flex items-center justify-start gap-x-3 p-0"
                        on:keydown
                        on:click={() => logout()}
                    >
                        <div class="flex w-[26px] items-center justify-center">
                            <Lottie jsonPath="lottie/nav_logout_icon.json" loop={false} />
                        </div>
                        <div class="hidden text-base lg:block">Logout</div>
                    </button>
                </div>
                <div class="hidden lg:block">
                    <div class="divider divider-primary opacity-10" />
                    <div class="pb-6">
                        <div class="flex items-center justify-center gap-x-4">
                            <div class="flex size-12 items-center justify-center rounded-full bg-white shadow-sm">
                                <i class="fa fa-user fa-lg text-primary"></i>
                            </div>
                            <div class="space-y-1">
                                <div>{$customerStore.fullName}</div>
                                <div class="text-xs">
                                    {$customerStore.store?.isSME
                                        ? $customerStore.employer.name
                                        : $customerStore.store?.name}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="min-h-screen w-full pt-[75px] lg:pl-[200px] lg:pt-0">
            <div
                class="flex flex-col items-center justify-center gap-y-4 border-b border-neutral p-6 text-4xl font-bold text-primary lg:flex-row lg:justify-between lg:gap-y-0"
            >
                <div>{title}</div>
                <div class="flex flex-row flex-wrap items-center justify-start gap-2">
                    <slot name="actions" />
                </div>
            </div>
            <div class="page">
                <slot name="page" />
            </div>
        </div>
    </div>
</div>

<style lang="scss">
    @use 'src/sass/base' as *;
    @use 'src/sass/tailwind';
</style>
