import { writable } from 'stores/localStorage';
import { getCustomer } from 'services/infinity';

const key = 'customer';
const initialValue = {};

function createStore() {
    const { subscribe, update, set } = writable(key, initialValue);

    return {
        subscribe,

        fetch: async function (customerId) {
            let customer = await getCustomer({ customerId });
            set(customer);

            return customer;
        },

        setCustomer: (customer) => {
            update((store) => {
                return { ...store, ...customer };
            });
        },

        reset: () => {
            set(initialValue);
        },
    };
}

export default createStore();
