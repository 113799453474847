<script>
    import Lottie from 'components/Lottie';

    export let title = null;
    export let subtitle = null;
    export let animationPath = 'lottie/loading-circle.json';
    export let animationLoop = true;
</script>

<div class="loader animate__animated animate__fadeIn">
    <div class="loader__icon animate__animated animate__zoomIn">
        <Lottie jsonPath={animationPath} loop={animationLoop} />
    </div>
    {#if title}
        <div class="loader__title">{title}</div>
    {/if}
    {#if subtitle}
        <div class="loader__subtitle">{subtitle}</div>
    {/if}
</div>

<style lang="scss">
    @use 'src/sass/base' as *;

    .loader {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        width: 100%;

        &__icon {
            width: 70px;
            height: 70px;
        }

        &__title {
            margin-top: em(20);
            font-size: em(20);
            font-weight: $fw-medium;
            color: darken($color-gray-dark, 50%);
            text-align: center;
            animation-iteration-count: infinite;
        }

        &__subtitle {
            margin-top: em(10);
            font-size: em(18);
            line-height: 1.2;
            color: $color-gray-dark;
            text-align: center;
        }
    }
</style>
