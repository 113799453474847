import AlertModal from 'AlertModal.svelte';

const alertModalOptions = {
    styleWindow: {
        width: '500px',
        borderRadius: '25px',
    },
    styleContent: {
        padding: 0,
    },
    closeButton: false,
    closeOnEsc: true,
    closeOnOuterClick: false,
};

export { alertModalOptions, AlertModal };
