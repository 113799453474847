<script>
    import LOANS_CONFIG from 'configs/loans';

    export let status;
    export let shake = false;
    export let isCustom = false;
</script>

{#if !isCustom}
    <div
        class="badge rounded-md font-medium text-white"
        class:shake
        class:badge-success={status == LOANS_CONFIG.LOAN_STATUSES.ACTIVE || status == LOANS_CONFIG.LOAN_STATUSES.PAID}
        class:badge-warning={status == LOANS_CONFIG.LOAN_STATUSES.PENDING}
        class:badge-error={status == LOANS_CONFIG.LOAN_STATUSES.WITHDRAWN ||
            status == LOANS_CONFIG.LOAN_STATUSES.REVERSED ||
            status == LOANS_CONFIG.LOAN_STATUSES.DENIED ||
            status == LOANS_CONFIG.LOAN_STATUSES.PAST_DUE}
    >
        {status}
    </div>
{:else}
    <div class="animate__animated badge badge-sm font-medium" class:animate-pulse={shake}>
        <slot />
    </div>
{/if}

<style lang="scss">
    @use 'src/sass/base' as *;
    @use 'src/sass/tailwind';
</style>
