export default {
    FILE_TYPES: {
        PROOF_OF_IDENTITY: {
            id: 3,
            label: 'Proof of Identity',
        },
        PROOF_OF_ADDRESS: {
            id: 4,
            label: 'Proof of Address',
        },
        PROFILE_PICTURE: {
            id: 5,
            label: 'Profile Picture',
        },
        PROOF_OF_PAYMENT: {
            id: 6,
            label: 'Payslip',
        },
        PROOF_OF_BUSINESS: {
            id: 7,
            label: 'Business Registration',
        },
        PROOF_OF_BUSINESS_FINANCIALS: {
            id: 10,
            label: 'Business Financial Statements',
        },
        PROOF_OF_BUSINESS_PICTURES: {
            id: 11,
            label: 'Business Pictures',
        },
    },
};
