import WaitlistModal from 'WaitlistModal.svelte';

const waitlistModalOptions = {
    styleWindow: {
        width: '500px',
        borderRadius: '25px',
    },
    styleContent: {
        padding: 0,
    },
    closeButton: false,
    closeOnEsc: true,
    closeOnOuterClick: true,
};

export { waitlistModalOptions, WaitlistModal };
