<script>
    export let success = false;
    export let urgent = false;
</script>

<div
    class="animate__animated badge badge-primary rounded-md text-white"
    class:animate-bounce={urgent}
    class:bg-success={success}
>
    <slot />
</div>

<style lang="scss">
    @use 'src/sass/base' as *;
    @use 'src/sass/tailwind';
</style>
