<script>
    import Footer from 'components/Footer';
</script>

<div class="page">
    <div class="content">
        <slot name="content" />
    </div>
    <Footer />
</div>

<style lang="scss">
    @use 'src/sass/base' as *;

    .page {
        display: flex;
        min-height: 100vh;
        flex-direction: column;
        margin: 0;
        padding: 0;
    }

    .content {
        position: relative;
        z-index: 1;
        flex: 1;
    }
</style>
