import { writable } from 'stores/localStorage';

const key = 'auth';
const initialValue = {
    customerId: null,
};

function createStore() {
    const { subscribe, update, set } = writable(key, initialValue);

    return {
        subscribe,

        setCustomerId: (customerId) => {
            update((store) => {
                return { ...store, customerId };
            });
        },

        reset: () => {
            set(initialValue);
        },
    };
}

export default createStore();
