import { get, writable } from 'svelte/store';

import { getTimeElapsed } from 'services/util';
import { getCustomerFiles, getCustomerFile } from 'services/infinity';

const FILES_EXPIRED_THRESHOLD_SECONDS = 240;

const initialValue = {
    files: [],
    bytes: {},
    dateLastUpdated: null,
};

function createStore() {
    const { subscribe, update, set } = writable(initialValue);

    return {
        subscribe,

        fetchFiles: async function (customerId) {
            let store = get(this);
            let timeSinceLastFetch = getTimeElapsed(new Date(), store.dateLastUpdated);

            if (!store.dateLastUpdated || timeSinceLastFetch >= FILES_EXPIRED_THRESHOLD_SECONDS) {
                console.debug(`Files expired after ${timeSinceLastFetch} seconds. Refreshing from API ...`);
                let { files } = await getCustomerFiles({ customerId });
                update((store) => {
                    return { ...store, dateLastUpdated: new Date(), files };
                });
            }

            return store;
        },

        fetchBytes: async function (customerId, fileId) {
            let store = get(this);
            let _file = store.bytes[fileId];

            if (_file) {
                return _file;
            } else {
                let { file } = await getCustomerFile({ customerId, fileId });
                update((store) => {
                    return {
                        ...store,
                        bytes: {
                            ...store.bytes,
                            [fileId]: file,
                        },
                    };
                });

                // Returns the base64 string
                return file;
            }
        },

        reset: () => {
            set(initialValue);
        },
    };
}

export default createStore();
